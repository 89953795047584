import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PoliticalNeutralityPage = () => (
  <Layout>
    <SEO title="Declaration of political neutrality" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <h1>Declaration of political neutrality</h1>
        <h2>No political affiliations</h2>
        <p><a href="https://nvotes.com/">nVotes</a>&nbsp;is a technological startup dedicated to carrying out legitimate and fair electoral processes, so it does not maintain political affiliation of any kind. Our goal is to be the world-wide reference in secure electronic voting with libre software and we focus on creating a technical solution to facilitate it.</p>
        <h2>Impartiality of partners</h2>
        <p>In order to maintain the impartiality of the electoral processes in which we participate, the partners of&nbsp;<a href="https://nvotes.com/">nVotes</a>&nbsp;have voluntarily signed a commitment of political neutrality, which obliges us not to issue any political manifestation publicly, on behalf of the company, In the exercise of our functions, or in a private capacity.</p>
        <h2>Neither donations nor contributions</h2>
        <p>The corporate policy of&nbsp;<a href="https://nvotes.com/">nVotes</a>&nbsp;prohibits the company from making any donation or direct or indirect financial contribution to political parties, candidates, or political organizations of any kind.</p>
      </div>
  </Layout>
)

export default PoliticalNeutralityPage
